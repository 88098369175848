<template>
  <div class="remain-problerms-add" :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
    <van-row>
      <van-col>
        <div class="baseInfo">
          问题-解决方案新增
        </div>
      </van-col>
    </van-row>
    <van-form @submit="saveSchemeInfo()" alidate-first @failed="onFailed" :rules="problermsForm">
      <div class="van-address-edit__fields">
        <div class="van-cell van-field">
          <van-field v-model="customerName" readonly label="客户名称" placeholder="客户名称"/>
        </div>
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <van-field
                  v-model="problerms"
                  readonly
                  rows="1"
                  autosize
                  label="问题描述"
                  type="textarea"
                  placeholder="请输入留言"
                  show-word-limit
              />
            </div>
          </div>
        </div>
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <van-field
                  v-model="scheme"
                  required
                  rows="5"
                  autosize
                  label="解决方案"
                  type="textarea"
                  placeholder="请输入解决方案"
                  show-word-limit
                  :rules="problermsForm.scheme"
              />
            </div>
          </div>
        </div>
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <van-field
                  v-model="newResult"
                  required
                  rows="5"
                  autosize
                  label="最新成果"
                  type="textarea"
                  placeholder="请输入最新成果"
                  show-word-limit
                  :rules="problermsForm.newResult"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="van-address-edit__buttons">
        <button class="van-button van-button--danger van-button--normal van-button--block van-button--round"
                style="background-color: #1989FA;border-color: #1989FA;">
          <div class="van-button__content"><span class="van-button__text">保存</span></div>
        </button>
        <button class="van-button van-button--default van-button--normal van-button--block van-button--round"
                @click="cancel">
          <div class="van-button__content"><span class="van-button__text">取消</span></div>
        </button>
      </div>
    </van-form>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import {AddressEdit, Toast} from 'vant';
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "RemainProblermsAdd",
  components: {CrmTabBar, AddressEdit},
  data() {
    return {
      //列表高度
      freshHeight: 0,
      customerName: '',
      problerms: '',
      scheme: '',
      newResult: '',
      problermsForm: {
        scheme: [
          {required: true, message: '请输入解决方案', trigger: 'blur'},
        ],
        newResult: [
          {required: true, message: '请输入最新成果', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.init()
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/problerm/getProblermsListDetailByCustomer.json',
          {
            crmCustomerid: this.$route.params.id,
            titleId: this.$route.params.title,
            createdPeople: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.list = res.data
            this.customerName = this.list[0].customerName
            this.problerms = this.list[0].problerms
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    saveSchemeInfo() {
      this.$ajax.post('/api/ajax/problerm/saveProblermScheme.json',
          {
            customerName: this.list[0].customerName,
            crmCustomerid: this.list[0].crmCustomerid,
            titleName: this.list[0].titleName,
            titleId: this.list[0].titleId,
            replayId: this.list[0].replayId,
            problerms: this.list[0].problerms,
            scheme: this.scheme,
            newResult: this.newResult,
            createdName: this.list[0].createdName,
            createdPeople: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          });
          setTimeout(() => {
            this.$router.replace({path: '/RemainProblermsDetail/' + this.$route.params.title + "/" + this.$route.params.id})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('添加失败');
      })
    },
    cancel() {
      this.$router.replace({path: '/RemainProblermsDetail/' + this.$route.params.title + "/" + this.$route.params.id})
    }
  }
}
</script>

<style scoped>
.remain-problerms-add .baseInfo {
  font-size: 10px;
  color: #BAB8B7;
  margin-top: 0px;
  padding: 5px;
}

.remain-problerms-add .van-address-edit__fields {
  overflow: hidden;
  border-radius: 8px;
  margin-top: 8px;
}

.remain-problerms-add {
  padding: 12px;
}

</style>